import React, { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { TextAnim } from '../index';
import './header.css'
import back from "./back.jpeg"
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { MdClear } from "react-icons/md"
import { RequestAPI } from '../../utils/request-api'
import { vote } from '../../api/api'

import { Footer } from '../../Component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Modal } from '../../Component';

import orignalimage from '../../assets/F2_F.jpeg';
import intrinsicimage from '../../assets/F2_I.jpeg';

import theoryfirst from '../../assets/theory-first.jpg';
import theorysecond from '../../assets/theory-second.jpg';


import f1 from "../../assets/1st_f.jpg"
import i1 from "../../assets/1st_i.jpg"

import f2 from "../../assets/2nd_f.jpg"
import i2 from "../../assets/2nd_i.jpg"

import f3 from "../../assets/3rd_f.jpg"
import i3 from "../../assets/3rd_i.jpg"

import f4 from "../../assets/4th_f.jpg"
import i4 from "../../assets/4th_i.jpg"


const Header = ({ prop, userEmail }) => {

    const focused = [f1, f2, f3, f4 ];
    const intrinsic = [i1, i2, i3, i4];

    const [vara, setVara] = useState(true);
    const [index, setIndex] = useState(0);
    const [voted, setVoted] = useState(false);
    const [loading, setLoading] = useState(false);
    const targetRef = useRef(null);

    const [openModal, setOpenModal] = useState(false);
    const [imageToShow, setImageToShow] = useState(f1);

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/Processing`;
        navigate(path);
    }


    const goToWebShop = () => {
        let path = `/Web_Shop`;
        navigate(path);
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }

    const goToRegistration = () => {
        let path = `/Register`;
        navigate(path);
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }

    const goToProcessing = () => {
        let path = `/Processing`;
        navigate(path);
    }

    const goToSignIn = () => {
        let path = `/Login`;
        navigate(path);
    }


    const submitVote = async (e) => {
        e.preventDefault();

        setLoading(true);

        const body = {
            email: userEmail,
            index: index,
        }

        try {
            const response = await RequestAPI(vote(body));
            setLoading(false);
            if (response.status === 200) {
                setVoted(true);
            }

        } catch (error) {
            console.log(error);
            setLoading(false)
        }

    }

    const pro = prop;


    return (
        <div className='mainContainer'>
            <img src={back} alt="Girl in a jacket" className='backgroundImage' />

        <div className='header_body'>
            <div className='header_text_white'>
                {!pro && <spam>K-12 Processing Site</spam>}
                {pro && <TextAnim text={"K-12 Processing Site"} />}
                <div className='blackText' >Intrinsic Processing reveals hidden features…</div>
                <div className='whiteText' ></div>
                <div className='header_image_row'>
                    <div className='imageHeader'>
                        <img src={orignalimage} alt='img' />
                        <div className='whiteText' >Original</div>
                    </div>
                    <div className='imageHeader'>
                        <img src={intrinsicimage} alt='img' />
                        <div className='whiteText' >Intrinsic</div>
                    </div>
                    <div className='textAndButtons'>
                        <div className='whiteText_alignLeft' >…as in the picture of this flower​</div>
                        <button type='button' className='button3' onClick={goToProcessing}>Process</button>
                    </div>
                </div>
                <br />
            </div>
        </div>

    </div>
    )
}

export default Header
