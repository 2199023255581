import React, { useState } from 'react';
import Slider from '../../Component/Slider/Slider'
import { useNavigate } from "react-router-dom";

import "./challenge.css"

import f1 from "../../assets/1st_f.jpg"
import i1 from "../../assets/1st_i.jpg"

import f2 from "../../assets/2nd_f.jpg"
import i2 from "../../assets/2nd_i.jpg"

import f3 from "../../assets/3rd_f.jpg"
import i3 from "../../assets/3rd_i.jpg"

import f4 from "../../assets/4th_f.jpg"
import i4 from "../../assets/4th_i.jpg"

import flash1 from "../../assets/F2_F.jpeg"
import flash2 from "../../assets/F2_I.jpeg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Footer, Modal } from '../../Component';
import { MdClear } from "react-icons/md"



const Chalange = () => {


    const [index, setIndex] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [imageToShow, setImageToShow] = useState(f1);

    const focused = [f1, f2, f3, f4];
    const intrinsic = [i1, i2, i3, i4];

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/Image_Submission`;
        navigate(path);
    }

    return (
        <div className='Challenge'>
            <h1>Image Submission</h1>
            <div className='challange_submissions'>
                <div className='challange_submission_text'>
                    <h1>Rules of Submission</h1>
                    <ol>
                        <li>Image may be of an appropriate subject matter.</li>
                        <p></p>
                        <li>Only one Image set submitted per person per Intrinsic Challange.</li>
                        <p></p>
                        <li>Intrinsic Images sets must be processed with the software in this website.</li>
                        <p></p>
                        <li>No additional software processing is permitted.</li>
                        <p></p>
                        <li>The winner retains the copyrights but permits Intrinsic S&I use and publication of the winning submissions.</li>
                    </ol>
                    <button type='button' className='blue_button' onClick={routeChange}>Submission Page</button>
                </div>
                <div className='challange_submission_image'>
                    <img src={flash1} alt="reload" />
                    <h2>Original</h2>
                </div>
                <div className='challange_submission_image'>
                    <img src={flash2} alt="reload" />
                    <h2>Intrinsic</h2>
                </div>
            </div>

            <div className='TextBox'>
                <div className='TextBlock'>

                </div>


            </div>
        </div>
    )
}

export default Chalange
