
import React, { Fragment, useState } from 'react';
import './terms.css';



import { useNavigate } from "react-router-dom";

const Terms = () => {

  let navigate = useNavigate();


  return (
    <Fragment>
    {
      <div className='container'>
         <div className='terms_title'>
                    <h1>Terms of Use</h1>
                    <p>These terms and conditions (“Terms”) govern your license, use of, and access to the Intrinsic Processing Site (the “Site” including the delivery of a custom website and processing of images) offered by Intrinsic S&I, LLC (“Intrinsic”). By licensing the Site, you agree to be bound by these Terms.</p>
                    <p>As part of the Site, Intrinsic will provide you with a custom website through which you may manage your images (the “Custom Website”) and access to and use of Intrinsic’s proprietary intrinsic processing technology (the “Technology”) through a cloud-based service.   ​</p>
                    <p><b>Intellectual Property</b>.  All rights, title and interest in and to the Technology is licensed by Intrinsic from the Center for Quantitative Cytometry (“CQC”). In connection with your purchase of the Site, Diffusers, and Processing Tokens, you will have the right to use the Technology to process your Images on a cloud basis and without any right to receive a copy or access to the underlying Technology. In no event may you copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, or otherwise exploit the Technology other than processing the Images as permitted herein. Any feedback, comments or suggestions you may provide regarding the Technology or Site may be used by Intrinsic in its sole discretion without further obligation to you. Intrinsic will provide you with a Custom Website using the company’s logos, images, etc.  Intrinsic will retain ownership of the underlying template and structure of the Custom Website including any underlying technology necessary for use of the Site. You will retain ownership of all your logos, images, text, etc. used in building the Custom Website.​</p>
                    <p>All images uploaded for processing through the Site (the “Images”) will remain your property.  Intrinsic does not claim any ownership in your Images. You are solely responsible for all content of the Images. ​</p>
                    <p><b>Accounts</b>. In order to use the Site, each user will need to create an account.  You agree that all information provided by you will be true, complete and accurate and that we may use <b>Terms and Conditions for Intrinsic Processing Site</b> such information in connection with your use of the Site.  You will be responsible for all activity under your accounts​</p>
                    <p><b>Fees and Payment</b>. You agree to pay all fees set forth on the Intrinsic website in connection with your license and use of the Site.  All fees are nonrefundable. We may modify our fees at any time by posting such modifications to our website or otherwise posting or notifying your through the Site or otherwise. ​</p>
                    <p><b>Termination</b>. You may terminate your account and use of the Site at any time by notice to us.  If you breach any of these Terms, Intrinsic will have the right to suspend, disable or terminate your use of the Site at its sole discretion upon written or electronic notice.  Intrinsic will not be liable to you or be obligated to refund any fees paid for termination of your account or use of the Site. ​</p>
                    <p><b>Disclaimer of Warranty</b>. You understand and agree that the Site and Technology, and all processed images, materials, information, products and services included therein, are provided to you on an "AS IS" and on an "AS AVAILABLE" basis. Without limiting the foregoing, INTRINSIC EXPLICITLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO THE SERVICES AND APPLICATION, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. Your use of the Site and Images, including any processed images or results of use of the Site, is at your sole discretion and risk. ​</p>
                    <p><b>Indemnity</b> You agree to defend, indemnify, and hold Intrinsic, its officers, directors, managers, employees and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Site, Images and any processed images.</p>
                    <p><b>Limitation</b> of Liability TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, INTRINSIC AND ITS SUBSIDIARIES, AFFILIATES, MANAGERS, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SITE; OR (II) ANY PROCESSED IMAGES OR OTHER CONTENT GENERATED BY THE SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT MYXX HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.​</p>
                    <p><b>​General</b> These Terms will be governed by and construed in accordance with the laws of the Commonwealth of Puerto Rico, without regard to or application of conflict of laws rules or principles.​</p>
                    
              </div> 
      </div>

    }
    </Fragment>
  )
}

export default Terms
